///
/// Massively by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* List */

	ol {
		list-style: decimal;
		margin: 0 0 _size(element-margin) 0;
		padding-left: 1.25rem;

		li {
			padding-left: 0.25rem;
		}
	}

	ul {
		list-style: disc;
		margin: 0 0 _size(element-margin) 0;
		padding-left: 1rem;

		li {
			padding-left: 0.5rem;
		}

		&.divided {
			list-style: none;
			padding-left: 0;

			li {
				border-top: solid 1px;
				padding: 0.5rem 0;

				&:first-child {
					border-top: 0;
					padding-top: 0;
				}
			}
		}

		&.icons {
			cursor: default;
			list-style: none;
			padding-left: 0;

			li {
				display: inline-block;
				padding: 0 0.5rem 0 0;
				vertical-align: middle;

				&:last-child {
					padding-right: 0;
				}

				.icon {
					&:before {
						width: 2.25rem;
						height: 2.25rem;
						line-height: 2.25rem;
						display: inline-block;
						text-align: center;
						border-radius: 100%;
						font-size: 1.25rem;
					}
				}
			}

			&.alt {
				li {
					.icon {
						&:before {
							@include vendor('transition', (
								'color #{_duration(transition)} ease-in-out',
								'background-color #{_duration(transition)} ease-in-out',
								'border-color #{_duration(transition)} ease-in-out',
								'box-shadow #{_duration(transition)} ease-in-out'
							));

							font-size: 1rem;
						}
					}
				}
			}
		}

		&.actions {
			cursor: default;
			list-style: none;
			padding-left: 0;

			li {
				display: inline-block;
				padding: 0 (_size(element-margin) * 0.5) 0 0;
				vertical-align: middle;

				&:last-child {
					padding-right: 0;
				}
			}

			&.small {
				li {
					padding: 0 (_size(element-margin) * 0.25) 0 0;
				}
			}

			&.vertical {
				li {
					display: block;
					padding: (_size(element-margin) * 0.5) 0 0 0;

					&:first-child {
						padding-top: 0;
					}

					> * {
						margin-bottom: 0;
					}
				}

				&.small {
					li {
						padding: (_size(element-margin) * 0.25) 0 0 0;

						&:first-child {
							padding-top: 0;
						}
					}
				}
			}

			&.fit {
				display: table;
				margin-left: (_size(element-margin) * -0.5);
				padding: 0;
				table-layout: fixed;
				width: calc(100% + #{(_size(element-margin) * 0.5)});

				li {
					display: table-cell;
					padding: 0 0 0 (_size(element-margin) * 0.5);

					> * {
						margin-bottom: 0;
					}
				}

				&.small {
					margin-left: (_size(element-margin) * -0.25);
					width: calc(100% + #{(_size(element-margin) * 0.25)});

					li {
						padding: 0 0 0 (_size(element-margin) * 0.25);
					}
				}
			}

			@include breakpoint(xsmall) {
				margin: 0 0 _size(element-margin) 0;

				li {
					padding: (_size(element-margin) * 0.5) 0 0 0;
					display: block;
					text-align: center;
					width: 100%;

					&:first-child {
						padding-top: 0;
					}

					> * {
						width: 100%;
						margin: 0 !important;

						&.icon {
							&:before {
								margin-left: -2rem;
							}
						}
					}
				}

				&.small {
					li {
						padding: (_size(element-margin) * 0.25) 0 0 0;

						&:first-child {
							padding-top: 0;
						}
					}
				}
			}
		}
	}

	dl {
		margin: 0 0 _size(element-margin) 0;

		dt {
			display: block;
			font-weight: _font(weight-bold);
			margin: 0 0 (_size(element-margin) * 0.5) 0;
		}

		dd {
			margin-left: _size(element-margin);
		}
	}

	@mixin color-list($p: null) {
		ul {
			&.divided {
				li {
					border-top-color: _palette($p, border);
				}
			}

			&.icons {
				li {
					a.icon {
						&:hover {
							&:before {
								color: _palette($p, accent);
							}
						}
					}
				}

				&.alt {
					li {
						.icon {
							&:before {
								box-shadow: inset 0 0 0 2px _palette($p, border);
							}
						}

						a.icon {
							&:hover {
								&:before {
									box-shadow: inset 0 0 0 2px _palette($p, accent);
								}
							}
						}
					}
				}
			}
		}
	}

	@include color-list;