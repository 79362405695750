///
/// Massively by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Form */

	$gutter: (_size(element-margin) * 0.75);

	form {
		@include vendor('display', 'flex');
		@include vendor('flex-wrap', 'wrap');
		width: calc(100% + #{$gutter * 2});
		margin: ($gutter * -1) 0 _size(element-margin) ($gutter * -1);

		> .field {
			@include vendor('flex-grow', '0');
			@include vendor('flex-shrink', '0');
			padding: $gutter 0 0 $gutter;
			width: calc(100% - #{$gutter * 1});

			&.half {
				width: calc(50% - #{$gutter * 0.5});
			}

			&.third {
				width: calc(#{100% / 3} - #{$gutter * (1 / 3)});
			}

			&.quarter {
				width: calc(25% - #{$gutter * 0.25});
			}
		}

		> .actions {
			@include vendor('flex-grow', '0');
			@include vendor('flex-shrink', '1');
			margin: ($gutter * 1.25) 0 0 $gutter !important;
			width: calc(100% - #{$gutter * 2});
		}

		&.alt {
			display: block;
			width: 100%;
			margin: 0 0 _size(element-margin) 0;

			> .actions {
				margin: 0 0 _size(element-margin) 0;
				width: 100%;
			}
		}

		@include breakpoint(xsmall) {
			$gutter: (_size(element-margin) * 0.75);

			width: calc(100% + #{$gutter * 2});
			margin: ($gutter * -1) 0 _size(element-margin) ($gutter * -1);

			> .field {
				padding: $gutter 0 0 $gutter;
				width: calc(100% - #{$gutter * 1});

				&.half {
					width: calc(100% - #{$gutter * 1});
				}

				&.third {
					width: calc(100% - #{$gutter * 1});
				}

				&.quarter {
					width: calc(100% - #{$gutter * 1});
				}
			}

			> .actions {
				margin: $gutter 0 0 $gutter;
				width: calc(100% - #{$gutter * 2});
			}
		}
	}

	label {
		display: block;
		font-family: _font(family-heading);
		font-weight: _font(weight-heading);
		line-height: 1.5;
		letter-spacing: 0.075em;
		font-size: 0.8rem;
		text-transform: uppercase;
		margin: 0 0 (_size(element-margin) * 0.375) 0;

		@include breakpoint(medium) {
			font-size: 0.9rem;
		}
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select,
	textarea {
		@include vendor('appearance', 'none');
		background: transparent;
		border-radius: _size(border-radius);
		border: solid 2px;
		color: inherit;
		display: block;
		outline: 0;
		padding: 0 1rem;
		text-decoration: none;
		width: 100%;

		&:invalid {
			box-shadow: none;
		}
	}

	.select-wrapper {
		@include icon;
		display: block;
		position: relative;

		&:before {
			content: '\f078';
			display: block;
			height: _size(element-height);
			line-height: _size(element-height);
			pointer-events: none;
			position: absolute;
			right: 0;
			text-align: center;
			top: 0;
			width: _size(element-height);
		}

		select::-ms-expand {
			display: none;
		}
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select {
		height: _size(element-height);
	}

	textarea {
		padding: 0.75rem 1rem;
	}

	input[type="checkbox"],
	input[type="radio"], {
		@include vendor('appearance', 'none');
		display: block;
		float: left;
		margin-right: -2rem;
		opacity: 0;
		width: 1rem;
		z-index: -1;

		& + label {
			@include icon;
			cursor: pointer;
			display: inline-block;
			font-size: 1rem;
			letter-spacing: 0;
			font-family: _font(family);
			text-transform: none;
			font-weight: _font(weight);
			padding-left: (_size(element-height) * 0.6) + 1rem;
			padding-right: 1rem;
			position: relative;

			&:before {
				border-radius: _size(border-radius);
				border: solid 2px;
				content: '';
				display: inline-block;
				height: (_size(element-height) * 0.6);
				left: 0;
				line-height: (_size(element-height) * 0.575);
				position: absolute;
				text-align: center;
				top: -0.125rem;
				width: (_size(element-height) * 0.6);
			}
		}

		&:checked + label {
			&:before {
				content: '\f00c';
			}
		}
	}

	input[type="checkbox"] {
		& + label {
			&:before {
				border-radius: _size(border-radius);
			}
		}
	}

	input[type="radio"] {
		& + label {
			&:before {
				border-radius: 100%;
			}
		}
	}

	::-webkit-input-placeholder {
		opacity: 1.0;
	}

	:-moz-placeholder {
		opacity: 1.0;
	}

	::-moz-placeholder {
		opacity: 1.0;
	}

	:-ms-input-placeholder {
		opacity: 1.0;
	}

	.formerize-placeholder {
		opacity: 1.0;
	}

	@mixin color-form($p: null) {
		label {
			color: _palette($p, fg-bold);
		}

		input[type="text"],
		input[type="password"],
		input[type="email"],
		select,
		textarea {
			border-color: _palette($p, border);

			&:focus {
				border-color: _palette($p, accent);
			}
		}

		select {
			option {
				background-color: _palette($p, bg);
				color: _palette($p, fg);
			}
		}

		.select-wrapper {
			&:before {
				color: _palette($p, border);
			}
		}

		input[type="checkbox"],
		input[type="radio"], {
			& + label {
				color: _palette($p, fg);

				&:before {
					border-color: _palette($p, border);
				}
			}

			&:checked + label {
				&:before {
					background-color: _palette($p, fg-bold);
					border-color: _palette($p, fg-bold);
					color: _palette($p, bg);
				}
			}

			&:focus + label {
				&:before {
					border-color: _palette($p, accent);
				}
			}
		}

		::-webkit-input-placeholder {
			color: _palette($p, fg-light) !important;
		}

		:-moz-placeholder {
			color: _palette($p, fg-light) !important;
		}

		::-moz-placeholder {
			color: _palette($p, fg-light) !important;
		}

		:-ms-input-placeholder {
			color: _palette($p, fg-light) !important;
		}

		.formerize-placeholder {
			color: _palette($p, fg-light) !important;
		}
	}

	@include color-form;