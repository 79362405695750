///
/// Massively by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Type */

	html {
		font-size: 16pt;

		@include breakpoint(xlarge) {
			font-size: 12pt;
		}

		@include breakpoint(large) {
			font-size: 11pt;
		}

		@include breakpoint(xxsmall) {
			font-size: 10pt;
		}
	}

	body {
		color: _palette(fg);
	}

	body, input, select, textarea {
		font-family: _font(family);
		font-weight: _font(weight);
		font-size: 1rem;
		line-height: 2.375;
	}

	a {
		@include vendor('transition', (
			'color #{_duration(transition)} ease-in-out',
			'background-color #{_duration(transition)} ease-in-out',
			'border-color #{_duration(transition)} ease-in-out',
			'box-shadow #{_duration(transition)} ease-in-out'
		));
		border-bottom: dotted 1px;
		text-decoration: none;

		&:hover {
			border-bottom-color: transparent;
		}
	}

	strong, b {
		font-weight: _font(weight-bold);
	}

	em, i {
		font-style: italic;
	}

	p {
		text-align: justify;
		margin: 0 0 _size(element-margin) 0;
	}

	h1, h2, h3, h4, h5, h6 {
		font-family: _font(family-heading);
		font-weight: _font(weight-heading);
		line-height: 1.5;
		letter-spacing: 0.075em;
		text-transform: uppercase;
		margin: 0 0 (_size(element-margin) * 0.5) 0;

		a {
			border-bottom: 0;
			color: inherit;
			text-decoration: none;
		}
	}

	h1 {
		font-size: 4rem;
		line-height: 1.1;
		margin: 0 0 _size(element-margin) 0;
	}

	h2 {
		font-size: 1.75rem;
		line-height: 1.3;
		margin: 0 0 (_size(element-margin) * 0.75) 0;
	}

	h3 {
		font-size: 1.25rem;
		margin: 0 0 (_size(element-margin) * 0.75) 0;
	}

	h4 {
		font-size: 1rem;
	}

	h5 {
		font-size: 0.9rem;
	}

	h6 {
		font-size: 0.8rem;
	}

	sub {
		font-size: 0.8rem;
		position: relative;
		top: 0.5rem;
	}

	sup {
		font-size: 0.8rem;
		position: relative;
		top: -0.5rem;
	}

	blockquote {
		border-left: solid 4px;
		font-style: italic;
		margin: 0 0 _size(element-margin) 0;
		padding: (_size(element-margin) / 4) 0 (_size(element-margin) / 4) _size(element-margin);
	}

	code {
		border-radius: _size(border-radius);
		border: solid 2px;
		font-family: _font(family-fixed);
		font-size: 0.9rem;
		margin: 0 0.25rem;
		padding: 0.25rem 0.65rem;
	}

	pre {
		-webkit-overflow-scrolling: touch;
		font-family: _font(family-fixed);
		font-size: 0.9rem;
		margin: 0 0 _size(element-margin) 0;

		code {
			display: block;
			line-height: 1.75;
			padding: 1rem 1.5rem;
			overflow-x: auto;
		}
	}

	hr {
		border: 0;
		border-bottom: solid 2px;
		margin: (_size(element-margin) * 1.5) 0;

		&.major {
			margin: (_size(element-margin) * 2.5) 0;
		}
	}

	.align-left {
		text-align: left;
	}

	.align-center {
		text-align: center;
	}

	.align-right {
		text-align: right;
	}

	@mixin color-typography($p: null) {
		@if $p != null {
			color: _palette($p, fg);
		}

		input, select, textarea {
			color: _palette($p, fg-bold);
		}

		a {
			color: _palette($p, fg-bold);
			border-bottom-color: transparentize(_palette($p, fg), 0.5);

			&:hover {
				border-bottom-color: transparent;
				color: _palette($p, accent) !important;
			}
		}

		strong, b {
			color: _palette($p, fg-bold);
		}

		h1, h2, h3, h4, h5, h6 {
			color: _palette($p, fg-bold);
		}

		blockquote {
			border-left-color: _palette($p, border);
		}

		code {
			background: _palette($p, border-bg);
			border-color: _palette($p, border);
		}

		hr {
			border-bottom-color: _palette($p, border);
		}
	}

	@include color-typography;